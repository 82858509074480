const dayMonthYearDigits: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
}; // 11/11/2021

const dateShort: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

const dayTextMonthText: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  weekday: 'long',
};

const dateShortText: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

const dateMonthShort: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
};

const dateMonthLong: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
};

const shortMonthOnly: Intl.DateTimeFormatOptions = {
  month: 'short',
};

const yearOnly: Intl.DateTimeFormatOptions = {
  year: 'numeric',
};

const hourMinuteSecond: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

const euroCurrency: Intl.NumberFormatOptions = {
  currency: 'EUR',
  maximumFractionDigits: 0,
  notation: 'standard',
  style: 'currency',
};

const madCurrency: Intl.NumberFormatOptions = {
  currency: 'MAD',
  maximumFractionDigits: 0,
  notation: 'standard',
  style: 'currency',
};

export default defineI18nConfig(() => ({
  datetimeFormats: {
    en: {
      dateMonthLong: dateMonthLong,
      dateMonthShort: dateMonthShort,
      dayMonthYearDigits,
      dayTextMonthText,
      hourMinute: {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
      },
      hourMinuteSecond,
      hourOnly: {
        hour: 'numeric',
        hour12: true,
      },
      minuteOnly: {
        minute: 'numeric',
      },
      secondOnly: {
        second: 'numeric',
      },
      short: dateShort,
      shortFullText: dateShortText,
      shortMonthOnly: shortMonthOnly,
      yearOnly: yearOnly,
    },
    fr: {
      dateMonthLong: dateMonthLong,
      dateMonthShort: dateMonthShort, // 11 nov.
      dayMonthYearDigits,
      dayTextMonthText,
      hourMinute: {
        // 11:40
        hour: 'numeric',
        hour12: false,
        minute: 'numeric',
      },
      hourMinuteSecond,
      hourOnly: {
        // 11h
        hour: 'numeric',
        hour12: false,
      },
      minuteOnly: {
        // 40m
        minute: 'numeric',
      },
      secondOnly: {
        // 40s
        second: 'numeric',
      },
      short: dateShort, // 11 nov. 2021
      shortFullText: dateShortText, // 11 novembre 2021
      shortMonthOnly: shortMonthOnly, // nov.
      yearOnly: yearOnly, // 2021
    },
  },
  fallbackLocale: 'fr',
  legacy: false,
  locale: 'fr',
  numberFormats: {
    en: {
      currency: euroCurrency,
      madCurrency: madCurrency,
    },
    fr: {
      currency: euroCurrency,
      madCurrency: madCurrency,
    },
  },
}));
